import React from 'react';

import StandardPage from '../components/StandardPage';
import Head from '../components/Head';
// import { LevelOne, LevelTwo } from '../components/OrderedLists';

export default () => (
  <StandardPage>
    <Head
      title="Security Policies"
      description="Bagel is committed to keeping customer and patient data private and secure. We keep your
          data safe so that you can focus on providing care. We take every precaution to ensure the safety and integrity of your data."
      url="/security/"
      image="/og_image.jpg"
    />
    <div className="section col ac jc bl60 pmargin">
      <div className="readable">
        <h2 className="title">Security Policies</h2>
        <h4 className="title">Bagel Health, Inc.</h4>
        <br />
        <p>
          Bagel is committed to keeping customer and patient data private and secure. We keep your
          data safe so that you can focus on providing care. We take every precaution to ensure the
          safety and integrity of your data.
        </p>
        <ul className="ml32">
          <li>
            <b>Data Center Security</b>: Bagel Health runs in highly secure data centers. Our
            hosting providers are regularly audited against comprehensive frameworks including SSAE
            16 and ISO 27001. All Bagel Health services run within private, secure network layers,
            addressable only through whitelisted gateways.
          </li>

          <li>
            <b>Data Encryption</b>: All data is encrypted whenever possible. All traffic is
            encrypted in transit with SSL/TLS. All data is encrypted at rest with full key/data
            segregation. We also continually review our code for OWASP, CVE, and NVD-reported
            vulnerabilities.
          </li>

          <li>
            <b>Data Access & Activity Audit</b>: All data access is restricted to approved employees
            based on job function. All access is logged and stored for auditing and anomaly
            detection. All changes to customer data are tracked via audit logs.
          </li>

          <li>
            <b>Web Application Security</b>: Bagel Health applications are built with industry best
            practice safeguards such as input data validation, CSRF protection, and password
            encryption.{' '}
          </li>

          <li>
            <b>Business Continuity</b>: The Bagel Health platform is designed to be resilient. We
            continuously implement and test contingency and disaster recovery plans as part of our
            operations cycle. Encrypted backups are performed every 24 hours. Bagel Health uses only
            hardened, best-in-class configurations for all of our services.
          </li>

          <li>
            <b>Implementation</b>: Bagel Health’s security program is defined by a formal set of
            policies and procedures, which are reviewed regularly by our Chief Technology Officer
            and executive team.
          </li>
        </ul>
        <p className="small bl40 mt32">Updated 6 December, 2018</p>
      </div>
    </div>
  </StandardPage>
);
